<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card
          :title="`Crear Marco Legal Específico para ${marcoLegalGeneralVersion.marco_legal_general.name} ${marcoLegalGeneralVersion.version}`"
        >
          <marco-legal-especifico-form />
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import MarcoLegalEspecificoForm from "@/components/MarcoLegalGeneral/MarcoLegalEspecificoForm.vue";
import { find } from "@/services/marcos_legales_generales_versions";
export default {
  components: {
    CCard,
    MarcoLegalEspecificoForm,
  },
  data() {
    return {
      marcoLegalGeneralVersionId: this.$route.params.versionId,
      marcoLegalGeneralId: this.$route.params.id,
      marcoLegalGeneralVersion: {
        version: "",
        marco_legal_general: {
          name: "",
        },
      },
    };
  },
  mounted() {
    this.getMarcoLegalGeneralVersion();
  },
  methods: {
    getMarcoLegalGeneralVersion() {
      find(this.marcoLegalGeneralVersionId).then((response) => {
        this.marcoLegalGeneralVersion = response.data;
      });
    },
  },
};
</script>
